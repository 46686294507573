// src/theme.ts

export default {
  presets: {
    light: {
      backgroundPrimary: '#E3E8EF', // Light grey-blue
      backgroundSecondary: '#FFFFFF', // White
      backgroundCardPrimary: '#F1F5FB', // Very light blue
      backgroundCardSecondary: '#D1E7FF', // Light blue
      primary: '#0056A3', // Police blue
      success: '#228200', // Success green
      info: '#158DE3', // Info blue
      danger: '#E42222', // Danger red
      warning: '#FFD43A', // Warning yellow
    },
    dark: {
      backgroundPrimary: '#0B1116', // Very dark grey-blue
      backgroundSecondary: '#1C1F26', // Dark grey
      backgroundCardPrimary: '#111827', // Dark grey-blue
      backgroundCardSecondary: '#0F172A', // Very dark grey-blue
      primary: '#0A74DA', // Police blue
      success: '#228200', // Success green
      info: '#158DE3', // Info blue
      danger: '#E42222', // Danger red
      warning: '#FFD43A', // Warning yellow
    },
  },
}
