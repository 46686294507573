<template>
  <VaLayout
    :top="{ fixed: true, order: 2 }"
  >
    <template #top>
      <AppNavbar :is-mobile="isMobile" />
    </template>

    <template #content>
      <main class="p-4 pt-0">
        <article>
          <RouterView />
        </article>
      </main>
    </template>
  </VaLayout>
</template>
