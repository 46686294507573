import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AppLayout from '../layouts/AppLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' }, // Redirect to 'dashboard' for any unmatched routes
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' }, // Redirect to 'dashboard'
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      // { 
      //   name: 'select-merchandise',
      //   path: 'dashboard/select-merchandise', 
      //   component: () => import('../pages/admin/dashboard/SelectMerchandise.vue'), 
      // },
      {
        name: 'purchasing-summary',
        path: 'dashboard/purchasing-summary',
        component: () => import('../pages/admin/dashboard/PurchasingSummary.vue'),
      },
      {
        name: 'payment-instructions',
        path: 'dashboard/payment-instructions',
        component: () => import('../pages/admin/dashboard/PaymentInstructions.vue'),
      },
      {
        name: 'pay',
        path: 'dashboard/pay',  // Use the expected full path relative to the parent layout
        component: () => import('../pages/admin/dashboard/pay.vue'),
      },    
        // navigateToDashBoardPage 
      {
        name: 'sessionError',
        path: 'dashboard/sessionError',
        component: () => import('../pages/admin/dashboard/SessionError.vue'),
      }    
    ],
  },
]

const router = createRouter({
  history: createWebHistory(), // Use HTML5 History API
  routes, // Register routes
})

export default router
